import React from 'react'
import Layout from '../components/layout'
import {Basic, Facebook, Twitter} from '../components/SEO'
import {metadata} from "../../config";
import loadable from "@loadable/component";
import Disclaimer from "../components/disclaimer/disclaimer";
import ReactHtmlParser, {convertNodeToElement} from 'react-html-parser';
import {delayMultiplicationFactor, googleScriptLoadingDelay, isBrowser} from "../utils/articleUtil";
import {orderBy as _orderBy} from 'lodash';
import {isMobile} from "react-device-detect";
import Helmet from "react-helmet";
import consts from "../constants/consts";
import Navigate from "../components/navigate";
import Faq from "../components/faq";

import '../css/components/_article.scss';
import CategoryTitleBordered from "../components/helpers/categoryTitleBordered";
import SubscriptionBanner from "../components/subscribe/Banner";
import ArticlePopularPosts from "../components/popularPosts/articlePopular";
import {GatsbyImage} from "gatsby-plugin-image";
import Highlights from "../components/highlights";

const AuthorDetails = loadable(() => import("./authorDetails"));
const WhatNext = loadable(() => import("../components/whatNext"));
const FbComments = loadable(() => import("../components/fbComments"));
const Share = loadable(() => import("../components/share/sharing"));
const MortgageCalculator = loadable(() => import("../components/mortgageCalculator"));
const PensionCalculator = loadable(() => import("../components/pensionCalculator"));

class Article extends React.Component {

    constructor(props) {
        super(props);
        this.data = props.pageContext.article;
        this.url = `${metadata.url}/${props.pageContext.url}`;
        this.categories = _orderBy(this.data.category, ['position'], ['asc']);
        this.subCategory = this.categories.find(item => item.treeParent !== null);
        this.longAdCount = 0;
        this.lastIndexOfPTag = 0;

        this.getRecommendedPosts();

        this.schemaArticle = {
            '@context': 'https://schema.org',
            '@type': this.data.json && this.data.json.faq ? 'FAQPage' : 'Article',
            author: {
                '@type': 'Person',
                name: this.data.author.name,
                url: `${metadata.url}/authors/${this.data.author.slug}`
            },
            "publisher": {
                "@type": "Organization",
                "name": metadata.defaultTitle,
                "logo": {
                    "@type": "ImageObject",
                    "url": `${metadata.url}/images/logos/logo.png`
                }
            },
            datePublished: this.data.publishedAt,
            description: this.data.excerpt,
            headline: this.data.seo.title,
            url: this.url,
            name: this.data.seo.title,
            wordCount: this.props.pageContext.wordCount,
            articleSection: this.subCategory.title
        }
        if (this.data.seo.image) {
            this.schemaArticle.image = {
                '@type': 'ImageObject',
                url: this.data.seo.image.url,
            }
        }
        if (this.data.json && this.data.json.faq) {
            this.schemaArticle.mainEntity = [];
            this.data.json.faq.forEach(faq => {
                this.schemaArticle.mainEntity.push({
                    "@type": "Question",
                    "name": faq.title,
                    "acceptedAnswer": {
                        "@type": "Answer",
                        "text": faq.text
                    }
                });
            })
        } else {
            this.schemaArticle.mainEntityOfPage = this.url;
        }
        this.state = {
            isSubscribeOpen: false
        }

    }

    onSubscriptionPopupStateChange = (state) => {
        this.setState({isSubscribeOpen: state})
    }

    getRecommendedPosts() {
        this.recommendedPosts = [];

        this.popularPosts = [];

        const slugs = [];
        let posts = [];
        const relatedArticlesObject = this.props.pageContext.relatedArticles;
        posts = posts
            .concat(relatedArticlesObject.tags10 || [])
            .concat(relatedArticlesObject.tags9 || [])
            .concat(relatedArticlesObject.tags8 || [])
            .concat(relatedArticlesObject.tags7 || [])
            .concat(relatedArticlesObject.tags6 || [])
            .concat(relatedArticlesObject.tags5 || [])
            .concat(relatedArticlesObject.tags4 || [])
            .concat(relatedArticlesObject.tags3 || [])
            .concat(relatedArticlesObject.tags2 || [])
            .concat(relatedArticlesObject.tags1 || [])
            .concat(relatedArticlesObject.sameCategory || [])
            .concat(relatedArticlesObject.random || []);

        for (let i = 0; i < posts.length; i++) {
            const post = posts[i];
            if (!slugs.includes(post.slug)) {
                this.recommendedPosts.push(post);
                slugs.push(post.slug);
            }
        }
        this.popularPosts = [...this.recommendedPosts];
    }

    getContent() {
        let content = this.data.content.replace(/(?:\r\n|\r|\n)/g, '');
        content = content.replace(/ aria-level="1"/g, '');
        content = content.replace(/ style="font-weight: 400;"/g, '');
        content = content.replace(/ padding-left:40px/g, '');
        content = content.replace(/src="https:\/\/www.youtube.com\/embed/g, "data-src=\"https://www.youtube.com/embed");
        content = content.replace(/<td> *<p>/g, "<td>");
        content = content.replace(/<\/p> *<\/td>/g, "</td>");
        return content;
    }

    transform = (node, index) => {
        // TODO fix this domnesting issue div within p tag
        /*if (node.type === 'tag' && node.name === 'img') {
            return <div style={{textAlign: "center"}}>
                {convertNodeToElement(node, index, null)}
            </div>;
        }*/
        if (node.type === 'tag' && node.name === 'table') {
            node.attribs.style = 'margin-left: auto; margin-right: auto;' + (node.attribs.style ? node.attribs.style : '');
            return convertNodeToElement(node, index, null)
        }
        if(node.type === 'tag' && node.name === 'p' && (!node.parent || node.parent.name === 'div')) {
            ++this.lastIndexOfPTag
            let customElements = [];
            if(isMobile && this.lastIndexOfPTag === 3) {
                customElements.push(<div key={"subscription-banner"} className={"mb-4"}>
                    <SubscriptionBanner onSubscriptionPopupStateChange={this.onSubscriptionPopupStateChange}/>
                </div>);
            }
            /*if (this.lastIndexOfPTag === 4) {
                customElements.push(<div key={"ad-4"} className="ad-container">
                    <div className="advertisement-text">Advertisement</div>
                    <div id="div-pt_incontent1_mobile"></div>
                    <div id="div-pt_incontent1_desktop"></div>
                </div>);
            }
            if (this.lastIndexOfPTag === 12) {
                customElements.push(<div key={"ad-12"} className="ad-container">
                    <div className="advertisement-text">Advertisement</div>
                    <div id="div-pt_incontent2_mobile"></div>
                    <div id="div-pt_incontent2_desktop"></div>
                </div>);
            }
            if (this.lastIndexOfPTag >= 16 && this.lastIndexOfPTag%8 === 0) {
                ++this.longAdCount;
                customElements.push(<div key={`ad-long-${this.longAdCount}`} className="ad-container">
                    <div className="advertisement-text">Advertisement</div>
                    <div id={`div-pt_incontent_long${this.longAdCount}`} className="long-ad-container"></div>
                </div>);
            }*/
            if (isMobile && this.lastIndexOfPTag === 5) {
                customElements.push(<WhatNext recommendedPosts={this.recommendedPosts.splice(0, isMobile ? 2 : 4)}/>);
            }

            if (customElements.length) {
                return <>
                    {convertNodeToElement(node, index, null)}
                    {customElements.map(element => element)}
                </>;
            }
        }
    }

    getUrlForFacebook() {
        if (isBrowser()) {
            return `${window.location.origin}/${this.props.pageContext.url}`;
        } else {
            return this.url;
        }
    }

    componentDidMount() {
        const adCount = this.longAdCount;

        /*setTimeout(() => {
            window.googletag = window.googletag || {cmd: []};
            googletag.cmd.push(function () {
                try {
                    for (let i = 1; i <= adCount; i++) {
                        const divId = `div-pt_incontent_long${i}`;
                        if (document.documentElement.clientWidth > 768) {
                            const slot = googletag.defineSlot('/22218985137/pensiontimes/pt_incontent2_desktop', [[300, 250], [336, 280]], divId);
                            slot && slot.addService(googletag.pubads());
                        } else {
                            const slot = googletag.defineSlot('/22218985137/pensiontimes/pt_incontent2_mobile', [300, 250], divId);
                            slot && slot.addService(googletag.pubads());
                        }
                    }
                    googletag.pubads().enableSingleRequest();
                    googletag.pubads().collapseEmptyDivs();
                    googletag.pubads().setCentering(true);
                    googletag.pubads().disableInitialLoad();
                    googletag.pubads().enableLazyLoad({
                        fetchMarginPercent: 200,
                        renderMarginPercent: 100,
                        mobileScaling: 2.0
                    });
                    googletag.enableServices();
                } catch (e) {
                    console.log(e);
                }
            });

            googletag.cmd.push(function () {
                googletag.display("div-pt_incontent1_mobile");
                googletag.display("div-pt_incontent1_desktop");
                googletag.display("div-pt_incontent2_mobile");
                googletag.display("div-pt_incontent2_desktop");
                for (let i = 1; i <= adCount; i++) {
                    const divId = `div-pt_incontent_long${i}`;
                    googletag.display(divId);
                }
            });
        }, adDisplayDelay() * delayMultiplicationFactor(isMobile));*/

        const youtubeVideoDisplay = () => {
            const vidDefer = document.getElementsByTagName('iframe');
            for (let i = 0; i < vidDefer.length; i++) {
                if (vidDefer[i].getAttribute('data-src')) {
                    vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
                }
            }
        }

        if (googleScriptLoadingDelay() === consts.SCRIPT_LOADING_TIME_WAIT) {
            window.onload = () => {
                setTimeout(() => {
                    youtubeVideoDisplay();
                }, 2000 * delayMultiplicationFactor(isMobile));
            };
        } else {
            setTimeout(() => {
                youtubeVideoDisplay();
            }, 2000 * delayMultiplicationFactor(isMobile));
        }

        const comparisonTable = document.querySelector('table.comparison');
        if(comparisonTable) {
            let isYes = false;
            let isNo = false;
            let isOptional = false;
            for (let row of comparisonTable.rows) {
                for (let cell of row.cells) {
                    let val = cell.innerText; // your code below
                    switch (val.toLowerCase()) {
                        case 'yes':
                            cell.innerHTML = '<span class="compare-value yes">&#10003;</span>';
                            isYes = true;
                            break;
                        case 'no':
                            cell.innerHTML = '<span class="compare-value no">&#10799;</span>';
                            isNo = true;
                            break;
                        case 'optional':
                            cell.innerHTML = '<span class="compare-value optional">&#10003;</span>';
                            isOptional = true;
                    }
                }
            }
            const div = document.createElement("div");
            div.setAttribute("class","flex justify-center");
            if(isYes) {
                const yesDiv = document.createElement("div");
                yesDiv.setAttribute("class","flex justify-center mx-2");
                const yesSpan = document.createElement("span");
                yesSpan.setAttribute("class","compare-value yes");
                yesSpan.textContent = "✓";
                yesDiv.appendChild(yesSpan);
                const textSpan = document.createElement("span");
                textSpan.setAttribute("class","ml-2");
                textSpan.textContent = "Full Cover"
                yesDiv.appendChild(textSpan);
                div.appendChild(yesDiv);
            }
            if(isOptional) {
                const optionalDiv = document.createElement("div");
                optionalDiv.setAttribute("class","flex justify-center mx-2");
                const optionalSpan = document.createElement("span");
                optionalSpan.setAttribute("class","compare-value optional");
                optionalSpan.textContent = "✓";
                optionalDiv.appendChild(optionalSpan);
                const textSpan = document.createElement("span");
                textSpan.setAttribute("class","ml-2");
                textSpan.textContent = "Optional Cover";
                optionalDiv.appendChild(textSpan);
                div.appendChild(optionalDiv);
            }
            if(isNo) {
                const noDiv = document.createElement("div");
                noDiv.setAttribute("class","flex justify-center mx-2");
                const noSpan = document.createElement("span");
                noSpan.setAttribute("class","compare-value no");
                noSpan.textContent = "⨯";
                noDiv.appendChild(noSpan);
                const textSpan = document.createElement("span");
                textSpan.setAttribute("class","ml-2");
                textSpan.textContent = "No Cover";
                noDiv.appendChild(textSpan);
                div.appendChild(noDiv);
            }

            comparisonTable.after(div);
        }
    }

    render() {
        this.lastIndexOfPTag = 0;
        return <Layout preloadImage={this.data.cover.responsiveImage} isSubscribeOpen={this.state.isSubscribeOpen} onSubscriptionPopupStateChange={this.onSubscriptionPopupStateChange}>
            <Helmet>
                <meta name="keywords" content={this.data.tags}/>
                <script type="application/ld+json">{JSON.stringify(this.schemaArticle)}</script>
                {this.props.pageContext.noindex && <meta name="robots" content="noindex"/>}
            </Helmet>
            <Basic seo={this.data.seo}/>
            <Twitter seo={this.data.seo} url={this.url}/>
            <Facebook seo={this.data.seo} url={this.url}/>
            <main className="wrapper main-data-container article-page">
                <div className={"w-full lg:flex"}>
                    <article className="">
                        <CategoryTitleBordered className={"uppercase"}
                                               parentLink={`/${this.subCategory.treeParent.slug}`}
                                               parentTitle={this.subCategory.treeParent.title}
                                               link={`/${this.subCategory.treeParent.slug}/${this.subCategory.slug}`}
                                               title={this.subCategory.title}/>
                        <header className="font-arial">
                            {/*{this.data.ptNewsVideo && <div className={"news-video mx-2 md:mx-auto"} style={{background: `url('https://img.youtube.com/vi/${getYouTubeID(this.data.ptNewsVideo)}/sddefault.jpg') no-repeat`}}>
                                <EmbedYoutube video={this.data.ptNewsVideo}/>
                            </div>}*/}
                            <div className={"flex flex-col lg:flex-row mb-4 lg:mb-8"}>
                                <div className={"flex-grow-2 lg:mr-4"}>
                                    <GatsbyImage className={"h-full w-full lg:w-[383px]"} alt={this.data.heading} image={this.data.cover.gatsbyImageData}/>
                                </div>

                                <div className={"flex-grow-3"}>
                                    <h1 className="text-2xl lg:text-4xl font-helvetica font-semibold mt-3 lg:mt-0 mb-0 leading-tight">{this.data.heading}</h1>
                                    <div className="flex items-center text-sm text-black text-opacity-50" data-datocms-noindex>
                                        <div>
                                            By <Navigate
                                            href={`/authors/${this.data.author.slug}`} className={"text-denim article-author-name"}>{this.data.author.name}</Navigate>
                                        </div>
                                        {/*<time className={"ml-2"}>{formatDate(this.data.publishedAt, 'mmmm dd, yyyy')}</time>*/}
                                        <span>&nbsp;·&nbsp;{this.props.pageContext.readingTime} min read</span>
                                    </div>
                                    <p className="font-semibold font-helvetica text-base lg:text-lg font-arial leading-snug mb-0 mt-4">{this.data.excerpt}</p>
                                </div>
                            </div>
                            {this.data.highlights && <Highlights className={"mb-4 lg:mb-6"} data={this.data.highlights}/>}

                            {/*<hr className={`border-t-default my-6 mx-10 header-separator`}/>*/}
                            {isMobile && <Share/>}
                            {this.data.json && this.data.json.faq &&
                            <Faq data={this.data.json.faq}
                                 title={this.data.json.faqTitle ? this.data.json.faqTitle : `${this.data.heading}: FAQs`}/>}
                            {this.subCategory.slug === 'mortgages' && (
                                <MortgageCalculator/>
                            )}
                            {this.subCategory.treeParent.slug === 'pensions-retirement' && (
                                <PensionCalculator/>
                            )}

                        </header>
                        <main doc-container="main" className={"relative"}>
                            <div id="article-content">
                                {ReactHtmlParser(this.getContent(), {transform: this.transform})}
                                {isMobile && <WhatNext recommendedPosts={this.recommendedPosts.splice(0, isMobile ? 2 : 4)}/>}
                            </div>
                            {!isMobile && <div className={"absolute"} style={{top: 0, left: '-65px'}}>
                                <Share/>
                            </div>}
                        </main>
                        <AuthorDetails author={this.data.author}
                                       deferImage={true} data-datocms-noindex
                                       bioClass={"text-sm leading-relaxed font-light"}/>
                        {/*{(this.lastIndexOfPTag < 8 || this.lastIndexOfPTag > 18)  && <WhatNext recommendedPosts={this.recommendedPosts.splice(0, isMobile ? 2 : 4)}/>}*/}
                        {/*<div className={"flex flex-col-reverse lg:flex-row items-center font-arial"}>
                            <AuthorDetails author={this.data.author}
                                           deferImage={true} data-datocms-noindex
                                           className={"flex items-center flex-1 lg:flex-grow-3 lg:pr-12"}
                                           nameClass={"text-lg ml-6 mr-2 text-black text-denim"}
                                           bioClass={"ml-6 text-lg text-black text-opacity-75 mb-4 leading-relaxed font-light"}/>
                            <div className={"flex-1 lg:flex-grow-1"}>
                                <Share/>
                            </div>
                        </div>*/}
                        {this.subCategory.treeParent && !['videos'].includes(this.subCategory.treeParent.slug) && <Disclaimer/>}
                        <FbComments url={this.getUrlForFacebook()}/>
                    </article>
                    {!isMobile && <ArticlePopularPosts className="ml-4" articles={this.popularPosts}/>}
                </div>
            </main>
        </Layout>
    }
}

export default Article
